import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/git-stash",
  "date": "2014-08-11",
  "title": "GIT STASH",
  "author": "admin",
  "tags": ["development", "git"],
  "featuredImage": "feature.jpg",
  "excerpt": "Say you are halfway done with work on a branch, but an issue with 'master' needs fixing NOW. You're not quite ready to commit what you're working on, but need to make a quick fix to master. Fear not, just stash your current changes so you can switch to master quickly."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash save
`}</code></pre>
    <p>{`Above command will save your modified files by `}<strong parentName="p">{`stashing`}</strong>{` them away. And restoring your last commit to your working copy.`}</p>
    <p>{`After you submit your fixes, and checked in your changes you can verify the status of your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git diff
git status
`}</code></pre>
    <p>{`this will result in: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nothing to commit (working directory clean)
`}</code></pre>
    <p>{`Now you can resume work on the feature where you stopped before fixing the master.`}<br parentName="p"></br>{`
`}{`First list all the stashes to make sure you get the right one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash list
`}</code></pre>
    <p>{`As you can see, your stash is in the stash list. Let's apply the stash so you can get back to work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash apply
`}</code></pre>
    <p>{`Ok, so now that you have all your stashed changes back, let's clean up the stash list.
Go ahead and drop any unwanted stashes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash drop
`}</code></pre>
    <h2>{`Conflicts`}</h2>
    <p>{`If you get a conflict when trying to apply your previous stash, you can perform a hard reset back to the last commit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git reset --hard HEAD
`}</code></pre>
    <p>{`Now that you have the file reset, use the pop command to apply and delete the stash at the same time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash pop
`}</code></pre>
    <h2>{`Keeping the index`}</h2>
    <p>{`For this you need to swap branches again, but this time some of your changes may ready to be committed. Stash all the unstaged changes, but leave the staged changes intact so they can be committed afterwards.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash save --keep-index
`}</code></pre>
    <h2>{`List Options`}</h2>
    <p>{`If there are so many changes, you may need to stash some more changes, but if it doesn't seem to be working on these untracked files you just created, try using the --include-untracked option to stash all those new files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash save --include-untracked
`}</code></pre>
    <p>{`If you've been stashing stuff all day since you keep having to make small quick fixes to master. Then you will have a bunch of stashed changes and you may not be sure which one you need to apply. You could look through them all one by one, or you could use the `}<strong parentName="p">{`--stat`}</strong>{`" option to list information about each stash. Give that a try.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash list --stat
`}</code></pre>
    <h2>{`Stash Details`}</h2>
    <p>{`You now have a list of stashes that are showing you more information, and you think you may have spotted the stash that you need. Take a more detailed look at the stash@{2} stash with the --patch option.`}</p>
    <h2>{`Stash Message`}</h2>
    <p>{`You're halfway through a large feature. Your team has decided that they want you to deploy what you have so far, but you have a smaller unfinished feature that isn't ready to be committed yet.
Go ahead and stash your current changes with the message Added method, so you know which stash has your changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash save "Added a method"
`}</code></pre>
    <h2>{`Stash Branching`}</h2>
    <p>{`Now that you've deployed the main part of your feature, you need to finish up what is left. Create a new branch  and apply your most current stash all in one command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git stash branch <name> stash@{0}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      